import round1 from "../../assets/zh/guaizhu/round-1.png";
import round2 from "../../assets/zh/guaizhu/round-2.png";
import roundDesc from "../../assets/zh/guaizhu/round-desc.png";
import app1 from "../../assets/zh/studio/app-1.png";
import app1desc from "../../assets/zh/studio/app-1-desc.png";
import app2 from "../../assets/zh/studio/app-2.png";
import app3 from "../../assets/zh/studio/app-3.png";
import app4 from "../../assets/zh/studio/app-4.png";
import app5 from "../../assets/zh/studio/app-5.png";
import app6 from "../../assets/zh/studio/app-6.png";
import app7 from "../../assets/zh/studio/app-7.png";
import envImg from "../../assets/zh/guaizhu/env-img.png";
import env1 from "../../assets/zh/guaizhu/env-(1).png";
import env2 from "../../assets/zh/guaizhu/env-(2).png";
import env3 from "../../assets/zh/guaizhu/env-(3).png";
import env4 from "../../assets/zh/guaizhu/env-(4).png";
import env5 from "../../assets/zh/guaizhu/env-(5).png";
import env6 from "../../assets/zh/guaizhu/env-(6).png";
import env7 from "../../assets/zh/guaizhu/env-(7).png";
import env8 from "../../assets/zh/guaizhu/env-(8).png";
import historyImg from "../../assets/zh/guaizhu/history.png";
import honorsImg from "../../assets/zh/guaizhu/honors.png";

export default {
  round1,
  round2,
  roundDesc,
  app1,
  app1desc,
  app1,
  app2,
  app3,
  app4,
  app5,
  app6,
  app7,
  envImg,
  env1,
  env2,
  env3,
  env4,
  env5,
  env6,
  env7,
  env8,
  historyImg,
  honorsImg,
};
