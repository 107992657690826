import { createContext } from "react";

export const LanguageContext = createContext(null);

export const zh = {
  Guaizhu: "乖猪",
};
export const en = {
  Guaizhu: "Guaizhu",
};

export const languageMap = {
  zh,
  en,
};
