import React, { useContext } from "react";
import { LanguageContext, languageMap } from "../../language/LanguageContext";

const { zh, en } = languageMap;

const Formatter = (props) => {
  const language = useContext(LanguageContext);
  const { text } = props;

  console.log("[language]", language);

  if (language.startsWith("zh")) {
    return zh[text];
  }

  return en[text];
};

export default Formatter;
