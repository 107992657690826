import React, { useState } from "react";
import Formatter from "./components/formatter";
import { LanguageContext } from "./language/LanguageContext";
import "./App.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/mousewheel";
import "swiper/css/effect-fade";
import "swiper/css/effect-cube";
import "swiper/css/effect-coverflow";
import logo from "./assets/logo.png";
import Guaizhu from "./components/guaizhu";
import Azal from "./components/azal";
import Zigzag from "./components/Zigzag";
import Studio from "./components/studio";
import la from "./assets/en.png";

function App() {
  const [language, setLanguage] = useState(navigator.language);
  const [comp, setComp] = useState("Guaizhu");
  const changeLanguage = () => {
    setLanguage(language.startsWith("zh") ? "en" : "zh");
  };

  const renderComp = () => {
    console.log(comp);
    switch (comp) {
      // case "Guaizhu":
      //   return <Guaizhu />;
      case "Azal":
        return <Azal />;
      case "Zigzag":
        return <Zigzag />;
      // case "Doli":
      //   return <Guaizhu />;
      case "Studio":
        return <Studio />;
      default:
        return <Azal />;
    }
  };

  return (
    <LanguageContext.Provider value={language}>
      <div className="App">
        <div className="App-header">
          <div className="row header-wrapper">
            <img src={logo} alt="logo" className="logo" />
            <div className="line"></div>
            <div className="row-around header-wrapper">
              {/* <div className="nav-item" onClick={() => setComp("Guaizhu")}>
                <Formatter text="Guaizhu" />
              </div> */}
              <div className="nav-item" onClick={() => setComp("Azal")}>
                Azal live
              </div>
              <div className="nav-item" onClick={() => setComp("Zigzag")}>
                Zigzag
              </div>
              {/* <div className="nav-item" onClick={() => setComp("Doli")}>
                Doli live
              </div> */}
              <div className="nav-item" onClick={() => setComp("Studio")}>
                Studio GPT
              </div>
              <div className="nav-item" onClick={changeLanguage}>
                <img src={la} className="nav-item-la" />
              </div>
            </div>
          </div>
        </div>
        {renderComp()}
      </div>
    </LanguageContext.Provider>
  );
}

export default App;
