import React, { useState, useEffect, useContext } from "react";
// import "./index.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, EffectFade } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/mousewheel";
import "swiper/css/effect-fade";
import "swiper/css/effect-cube";
import "swiper/css/effect-coverflow";
import { LanguageContext } from "../../language/LanguageContext";

import enAssets from "./assets.en";
import zhAssets from "./assets.zh";
const assets = {
  en: enAssets,
  zh: zhAssets,
};

function Zigzag(props) {
  const [isEnd, setIsEnd] = useState(false);
  const language = useContext(LanguageContext);
  const imgs = language?.startsWith("zh") ? assets.zh : assets.en;

  useEffect(() => {
    const wrappers = document.getElementsByClassName("wrapper");
    if (isEnd) {
      document.body.style.backgroundColor = "#f5e9ba";
      for (let el of wrappers) {
        el.style.backgroundColor = "#f5e9ba";
      }
    } else {
      document.body.style.backgroundColor = "#f5f5f5";
      for (let el of wrappers) {
        el.style.backgroundColor = "#f5f5f5";
      }
    }
  }, [isEnd]);

  return (
    <div className="content">
      <Swiper
        key="azal"
        mousewheel={true}
        modules={[Mousewheel, EffectFade]}
        effect="fade"
        className="swiper-wrapper"
        spaceBetween={50}
        slidesPerView={1}
        onSlideChange={(value) => {
          setIsEnd(value.isEnd);
        }}
        direction="vertical"
      >
        <SwiperSlide>
          <div className="wrapper">
            <img src={imgs.app1} alt="" className="app-1-logo" />
            <div className="process-line">
              <div className="line-dot-blue" style={{ left: -11 }}>
                <div className="line-dot-blue-centra"></div>
              </div>
              <div className="line-dot-gray" style={{ right: -3 }}></div>
            </div>
            <img src={imgs.app1desc} alt="" className="app-1-desc" />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="wrapper">
            <img src={imgs.app1} alt="" className="app-1-logo" />
            <div
              className="process-line"
              style={{ backgroundColor: "#4f72ee" }}
            >
              <div
                className="line-dot-blue-centra"
                style={{ position: "absolute", lef: -7, top: -7 }}
              ></div>
              <div className="line-dot-blue" style={{ right: -11 }}>
                <div className="line-dot-blue-centra"></div>
              </div>
            </div>
            <img src={imgs.app2} alt="" className="app-1-desc" />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="wrapper">
            <img src={imgs.app3} alt="" className="app-1-about" />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="wrapper">
            <img src={imgs.envImg} alt="" className="app-1-env-img" />

            <div className="app-1-env-list">
              <img src={imgs.env1} alt="" className="app-1-env-item" />
              <img src={imgs.env2} alt="" className="app-1-env-item" />
              <img src={imgs.env3} alt="" className="app-1-env-item" />
              <img src={imgs.env4} alt="" className="app-1-env-item" />
              <img src={imgs.env5} alt="" className="app-1-env-item" />
              <img src={imgs.env6} alt="" className="app-1-env-item" />
              <img src={imgs.env7} alt="" className="app-1-env-item" />
              <img src={imgs.env8} alt="" className="app-1-env-item" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="wrapper">
            <img src={imgs.historyImg} alt="" className="app-1-history" />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="wrapper">
            <img src={imgs.honorsImg} alt="" className="app-1-honors" />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default Zigzag;
