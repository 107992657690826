import app1 from "../../assets/zh/zigzag/app-1.png";
import app1desc from "../../assets/zh/zigzag/app-1-desc.png";
import app2 from "../../assets/zh/zigzag/app-2.png";
import app3 from "../../assets/zh/zigzag/app-3.png";
import renxingAboutUs from "../../assets/zh/guaizhu/renxing-about-us.png";
import envImg from "../../assets/zh/guaizhu/env-img.png";
import env1 from "../../assets/zh/guaizhu/env-(1).png";
import env2 from "../../assets/zh/guaizhu/env-(2).png";
import env3 from "../../assets/zh/guaizhu/env-(3).png";
import env4 from "../../assets/zh/guaizhu/env-(4).png";
import env5 from "../../assets/zh/guaizhu/env-(5).png";
import env6 from "../../assets/zh/guaizhu/env-(6).png";
import env7 from "../../assets/zh/guaizhu/env-(7).png";
import env8 from "../../assets/zh/guaizhu/env-(8).png";
import historyImg from "../../assets/zh/guaizhu/history.png";
import honorsImg from "../../assets/zh/guaizhu/honors.png";

export default {
  app1,
  app1desc,
  app2,
  app3,
  renxingAboutUs,
  envImg,
  env1,
  env2,
  env3,
  env4,
  env5,
  env6,
  env7,
  env8,
  historyImg,
  honorsImg,
};
